import { Map } from "immutable";

import {

  FIND_APPOINTMENT_TYPES_REQUEST,
  FIND_APPOINTMENT_TYPES_SUCCESS,
  FIND_APPOINTMENT_TYPES_ERROR,

  FIND_DIARY_REQUEST,
  FIND_DIARY_SUCCESS,
  FIND_DIARY_ERROR,

  FIND_DIARY_DAY_REQUEST,
  FIND_DIARY_DAY_SUCCESS,
  FIND_DIARY_DAY_ERROR,

  FIND_TIME_SLOTS_REQUEST,
  FIND_TIME_SLOTS_SUCCESS,
  FIND_TIME_SLOTS_ERROR,

  FIND_PACKAGES_REQUEST,
  FIND_PACKAGES_SUCCESS,
  FIND_PACKAGES_ERROR,

  CHECK_TIMESLOTS_REQUEST,
  CHECK_TIMESLOTS_SUCCESS,
  CHECK_TIMESLOTS_ERROR,

  FIND_CARDS_REQUEST,
  FIND_CARDS_SUCCESS,
  FIND_CARDS_ERROR

} from "../actions/appointment";

const initialState = Map({

  findAppointmentTypesLoading: false,
  findAppointmentTypesData: [],
  findAppointmentTypesError: null,

  findDiaryLoading: false,
  findDiaryData: [],
  findDiaryError: false,

  findDiaryDayLoading: false,
  findDiaryDayData: [],
  findDiaryDayError: false,

  findTimeSlotsLoading: false,
  findTimeSlotsData: [],
  findTimeSlotsError: false,

  findPackagesLoading: false,
  findPackagesData: [],
  findPackagesError: false,

  checkTimeslotsLoading: false,
  checkTimeslotsData: [],
  checkTimeslotsError: false,

  findCardsLoading: false,
  findCardsData: [],
  findCardsError: false

});

const actionMap = {

  [FIND_APPOINTMENT_TYPES_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findAppointmentTypesLoading: true,
        findAppointmentTypesData: [],
        loginError: null,
      })
    );
  },
  [FIND_APPOINTMENT_TYPES_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findAppointmentTypesLoading: false,
        findAppointmentTypesData: action.data?.response?.data,
      })
    );
  },
  [FIND_APPOINTMENT_TYPES_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findAppointmentTypesLoading: false,
        findAppointmentTypesError: action.error && action.error.data ? action.error.data : {},
        findAppointmentTypesData: []
      })
    );
  },
  [FIND_DIARY_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findDiaryLoading: true,
        findDiaryData: [],
        findDiaryError: null,
      })
    );
  },
  [FIND_DIARY_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findDiaryLoading: false,
        findDiaryData: action.data?.response?.data,
      })
    );
  },
  [FIND_DIARY_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findDiaryLoading: false,
        findDiaryError: action.error && action.error.data ? action.error.data : {},
        findDiaryData: []
      })
    );
  },
  [FIND_DIARY_DAY_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findDiaryDayLoading: true,
        findDiaryDayData: [],
        findDiaryDayError: null,
      })
    );
  },
  [FIND_DIARY_DAY_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findDiaryDayLoading: false,
        findDiaryDayData: action.data?.response?.data,
      })
    );
  },
  [FIND_DIARY_DAY_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findDiaryDayLoading: false,
        findDiaryDayError: action.error && action.error.data ? action.error.data : {},
        findDiaryDayData: []
      })
    );
  },

  [FIND_TIME_SLOTS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findTimeSlotsLoading: true,
        findTimeSlotsData: [],
        findTimeSlotsError: null,
      })
    );
  },
  [FIND_TIME_SLOTS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findTimeSlotsLoading: false,
        findTimeSlotsData: action.data?.response?.data,
      })
    );
  },
  [FIND_TIME_SLOTS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findTimeSlotsLoading: false,
        findTimeSlotsError: action.error && action.error.data ? action.error.data : {},
        findTimeSlotsData: []
      })
    );
  },

  [FIND_PACKAGES_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findPackagesLoading: true,
        findPackagesData: [],
        findPackagesError: null,
      })
    );
  },
  [FIND_PACKAGES_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findPackagesLoading: false,
        findPackagesData: action.data?.response?.data,
      })
    );
  },
  [FIND_PACKAGES_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findPackagesLoading: false,
        findPackagesError: action.error && action.error.data ? action.error.data : {},
        findPackagesData: []
      })
    );
  },

  [CHECK_TIMESLOTS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        checkTimeslotsLoading: true,
        checkTimeslotsData: [],
        checkTimeslotsError: null,
      })
    );
  },
  [CHECK_TIMESLOTS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        checkTimeslotsLoading: false,
        checkTimeslotsData: action.data?.response?.data,
      })
    );
  },
  [CHECK_TIMESLOTS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        checkTimeslotsLoading: false,
        checkTimeslotsError: action.error && action.error.data ? action.error.data : {},
        checkTimeslotsData: []
      })
    );
  },


  [FIND_CARDS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findCardsLoading: true,
        findCardsData: [],
        findCardsError: null,
      })
    );
  },
  [FIND_CARDS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findCardsLoading: false,
        findCardsData: action.data?.response?.data,
      })
    );
  },
  [FIND_CARDS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findCardsLoading: false,
        findCardsError: action.error && action.error.data ? action.error.data : {},
        findCardsData: []
      })
    );
  },


}
export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
